<template>
	<div class="wrap">
		<div class="title title-vertical flex-space-between">
			<span>{{ pathName == '' ? $route.meta.pathName : pathName }}</span>
		</div>
		<List></List>
	</div>
</template>

<script>
import List from '@/components/list/list.vue'

export default {
	props: ['index'],
	components: {List},
	data() {
		return {
			pathName: ''
		}
	},

	mounted() {
	},

	methods: {},
	watch: {
		index: {
			handler: function (val) {
				console.log(val)
				this.pathName = this.$route.meta.pathName
			},
			immediate: true
		}
	}
}
</script>

<style lang="less" scoped>
.wrap {
	padding: 30px 0 69px 0;
	background: #fff;
}
</style>
